import { useState, useEffect } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Button,
  Row,
  Col,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import BroadcastTab from "./Partials/BroadcastTab";
import DataTab from "./Partials/DataTab";
import HistoryTab from "./Partials/HistoryTab";

const Main = () => {
  const [tab, setTab] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    document.cookie = "login=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    navigate("/login");
  };

  useEffect(() => {
    const cookies = document.cookie.split(";");
    const queryParams = new URLSearchParams(location.search);

    const paramsValue = queryParams.get("tab");
    if (paramsValue) {
      setTab(paramsValue);
    } else {
      setTab("1");
    }

    const cookie = cookies[0].trim();
    if (!cookie.startsWith("login=")) {
      navigate("/login");
    }
    setLoading(false);
  }, [location.search]);

  if (loading) {
    return <></>;
  }

  return (
    <>
      <Container
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Row xs={10}>
          <Col>
            <Nav tabs>
              <NavItem>
                <NavLink href="#/?tab=1" active={tab === "1"}>
                  BroadCast
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#/?tab=2" active={tab === "2"}>
                  Data
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#/?tab=3" active={tab === "3"}>
                  History
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={tab}>
              <TabPane tabId={"1"}>{tab === "1" && <BroadcastTab />}</TabPane>
              <TabPane tabId={"2"}>{tab === "2" && <DataTab />}</TabPane>
              <TabPane tabId={"3"}>{tab === "3" && <HistoryTab />}</TabPane>
            </TabContent>
          </Col>
        </Row>
        <Row xs={2}>
          <Col>
            <Button color="danger" onClick={handleLogout}>
              Logout
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Main;
