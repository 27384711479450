export interface Status {
  PENDING: string;
  FAILED: string;
  DONE: string;
}

export const STATUS: Status = {
  PENDING: "warning",
  FAILED: "danger",
  DONE: "success",
};
