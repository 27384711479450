import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import {
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  Container,
  Col,
  Row,
} from "reactstrap";

interface Column {
  key: string;
  header: string | ((data: any) => React.ReactNode);
  render?: (data: any) => React.ReactNode;
}

interface DataTableProps {
  columns: Column[];
  data: any[];
  total: number;
  page: number;
  perPage: number;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  displayLength?: number[];
}

const DataTable: React.FC<DataTableProps> = ({
  columns,
  data,
  total,
  search,
  setSearch,
  page,
  perPage,
  setPerPage,
  setPage,
  displayLength = [10, 20, 50, 100],
}) => {
  const totalPage = Math.ceil(total / perPage);

  return (
    <>
      <Container className="mt-2">
        <Row>
          <Col>
            <Input
              name="per_page"
              type="select"
              defaultValue={perPage}
              bsSize="sm"
              style={{ width: "12.5%" }}
              onChange={(e) => {
                setPerPage(parseInt(e.target.value));
                setPage(0);
              }}
            >
              {displayLength.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Input>
          </Col>
          <Col>
            <Input
              className="w-25 float-end"
              name="search"
              type="search"
              defaultValue={search}
              bsSize="sm"
              placeholder={"Search..."}
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(0);
              }}
            ></Input>
          </Col>
        </Row>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              {columns.map((column) => (
                <th key={column.key}>
                  {typeof column.header === "function"
                    ? column.header(data)
                    : column.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td
                  colSpan={columns.length + 1}
                  style={{ textAlign: "center" }}
                >
                  No data found
                </td>
              </tr>
            ) : (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  {columns.map((column) => (
                    <td key={column.key}>
                      {column.render ? column.render(item) : item[column.key]}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <Pagination size="sm" className="float-end">
          {totalPage !== 0 && (
            <PaginationItem disabled={page === 0}>
              <PaginationLink onClick={() => setPage(0)}>
                First Page
              </PaginationLink>
            </PaginationItem>
          )}
          <PaginationItem disabled={page === 0 || totalPage === 0}>
            <PaginationLink onClick={() => setPage(page - 1)}>
              Previous
            </PaginationLink>
          </PaginationItem>
          {[...Array(totalPage)].map((_, index) => {
            if (index >= page - 2 && index <= page + 2) {
              return (
                <PaginationItem key={index} active={index === page}>
                  <PaginationLink onClick={() => setPage(index)}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              );
            } else if (index === page - 3 || index === page + 3) {
              return (
                <PaginationItem key={index}>
                  <PaginationLink>...</PaginationLink>
                </PaginationItem>
              );
            } else {
              return <Fragment key={index}></Fragment>;
            }
          })}
          <PaginationItem disabled={page === totalPage - 1 || totalPage === 0}>
            <PaginationLink next onClick={() => setPage(page + 1)}>
              Next
            </PaginationLink>
          </PaginationItem>
          {totalPage !== 0 && (
            <PaginationItem disabled={page === totalPage - 1}>
              <PaginationLink onClick={() => setPage(totalPage - 1)}>
                Last Page
              </PaginationLink>
            </PaginationItem>
          )}
        </Pagination>
      </Container>
    </>
  );
};

export default DataTable;
