import axios from "axios";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { loadingAtom } from "../atom";
import DataTable from "../Widget/DataTable";
import { Badge, Row, Col, Input, Button, Label } from "reactstrap";
import { Status, STATUS } from "../_status";
import moment from "moment";

const HistoryTab = () => {
  const [loading, setLoading] = useAtom(loadingAtom);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env
          .REACT_APP_API_URL!}/history?per_page=${perPage}&page=${page}&start_date=${startDate} 00:00:00&end_date=${endDate} 23:59:59&search=${search}`
      )
      .then((result) => {
        setData(result.data.data);
        setTotal(result.data.total);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        setLoading(false);
      });
  }, [page, setLoading, perPage, startDate, endDate, search]);

  return (
    <>
      <DataTable
        columns={[
          {
            key: "created_at",
            header: "Tanggal",
            render: (item) => moment(item.created_at).format("YYYY-MM-DD"),
          },
          {
            key: "name",
            header: "Nama",
          },
          {
            key: "number",
            header: "No. HP",
          },
          {
            key: "user_agent",
            header: "User Agent",
          },
          {
            key: "ip_address",
            header: "IP Address",
          },
          {
            key: "status",
            header: "Status",
            render: (item) => (
              <Badge pill color={STATUS[item.status as keyof Status]}>
                {item.status}
              </Badge>
            ),
          },
        ]}
        total={total}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        search={search}
        setSearch={setSearch}
        data={data}
      />
    </>
  );
};

export default HistoryTab;
