import { useEffect, useState } from "react";
import { checkedAtom, loadingAtom } from "../atom";
import { useAtom } from "jotai";
import axios from "axios";
import DataTable from "../Widget/DataTable";
import { Button, Input } from "reactstrap";
import { toast } from "react-toastify";
import UploadCSVModal from "./DataTab/UploadCSVModal";
import ClearDataModal from "./DataTab/ClearDataModal";

const DataTab = () => {
  const [loading, setLoading] = useAtom(loadingAtom);
  const [checked, setChecked] = useAtom(checkedAtom);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showClearDataModal, setShowClearDataModal] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [checkPage, setCheckPage] = useState([false]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env
          .REACT_APP_API_URL!}/all?per_page=${perPage}&page=${page}&search=${search}`
      )
      .then((result) => {
        setData(result.data.data);
        setTotal(result.data.total);
        const pluckData = result.data.data.map(({ id }: { id: number }) => id);
        if (pluckData.every((c: number) => checked.includes(c))) {
          const updatedData = [...checkPage];
          updatedData[page] = true;
          setCheckPage(updatedData);
        } else {
          const updatedData = [...checkPage];
          updatedData[page] = false;
          setCheckPage(updatedData);
        }
      })
      .catch((err) => {
        toast.error(err.toString());
      })
      .then(() => {
        setLoading(false);
      });
  }, [
    page,
    setLoading,
    perPage,
    total,
    showUploadModal,
    showClearDataModal,
    search,
  ]);

  return (
    <>
      <div className="d-flex justify-content-between m-2">
        <Button
          className="col-md-1"
          size="sm"
          color="info"
          onClick={() => setShowUploadModal(true)}
        >
          Upload CSV
        </Button>
        <Button
          className="col-md-1"
          size="sm"
          color="warning"
          onClick={() => setShowClearDataModal(true)}
        >
          Clear Data
        </Button>
      </div>
      <DataTable
        columns={[
          {
            key: "name",
            header: "Nama",
          },
          {
            key: "number",
            header: "No. HP",
          },
          {
            key: "id",
            header: (_) => {
              return (
                data.length > 0 && (
                  <Input
                    type="checkbox"
                    checked={checkPage[page] ? checkPage[page] : false}
                    onChange={(e) => {
                      const updatedData = [...checkPage];
                      updatedData[page] = e.target.checked;
                      setCheckPage(updatedData);

                      if (e.target.checked) {
                        setChecked((prevChecked) => {
                          const newDataIds = data.map(
                            (item: { id: number }) => item.id
                          );
                          const idsToAdd = newDataIds.filter(
                            (id) => !prevChecked.includes(id)
                          );
                          return [...prevChecked, ...idsToAdd];
                        });
                      } else {
                        setChecked(
                          checked.filter(
                            (number) =>
                              !data
                                .map((item: { id: number }) => item.id)
                                .includes(number)
                          )
                        );
                      }
                    }}
                  />
                )
              );
            },
            render: (item) => {
              return (
                <Input
                  type="checkbox"
                  checked={checked.includes(item.id)}
                  onChange={(e) => {
                    let checkArray = checked;
                    if (e.target.checked) {
                      setChecked((prevChecked) => [...prevChecked, item.id]);
                      checkArray = [...checked, item.id];
                    } else {
                      setChecked((prevChecked) =>
                        prevChecked.filter((number) => number !== item.id)
                      );
                      checkArray = checked.filter(
                        (number) => number !== item.id
                      );
                    }

                    const allIdsIncluded = data.every(
                      (object: { id: number }) => checkArray.includes(object.id)
                    );

                    const updatedCheckPage = [...checkPage];
                    updatedCheckPage[page] = allIdsIncluded;
                    setCheckPage(updatedCheckPage);
                  }}
                />
              );
            },
          },
        ]}
        total={total}
        data={data}
        page={page}
        perPage={perPage}
        search={search}
        setPerPage={setPerPage}
        setSearch={setSearch}
        setPage={setPage}
      />
      <UploadCSVModal
        isVisible={showUploadModal}
        setVisible={setShowUploadModal}
      />
      <ClearDataModal
        isVisible={showClearDataModal}
        setVisible={setShowClearDataModal}
      />
    </>
  );
};

export default DataTab;
