import axios from "axios";
import { useAtom } from "jotai";
import React from "react";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { loadingAtom } from "../../atom";

interface ClearGalleryModalProps {
  isVisible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ClearGalleryModal: React.FC<ClearGalleryModalProps> = ({
  isVisible,
  setVisible,
}) => {
  const [loading, setLoading] = useAtom(loadingAtom);

  const handleSubmit = () => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/clear-gallery`)
      .then((result) => {
        toast.success(result.data.message);
      })
      .catch((err) => {
        toast.error(err.data.message);
      })
      .then(() => {
        setLoading(false);
        setVisible(false);
      });
  };

  return (
    <>
      <Modal isOpen={isVisible} toggle={() => setVisible(false)}>
        <ModalHeader toggle={() => setVisible(false)}>
          Clear Gallery
        </ModalHeader>
        <Form onSubmit={handleSubmit}>
          <ModalBody>
            <p>Apakah anda yakin untuk hapus semua gambar?</p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onSubmit={handleSubmit} disabled={loading}>
              Submit
            </Button>
            <Button color="secondary" onClick={() => setVisible(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default ClearGalleryModal;
