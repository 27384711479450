import { FormEvent, useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");

  useEffect(() => {
    const cookies = document.cookie.split(";");

    const cookie = cookies[0].trim();
    if (cookie.startsWith("login=")) {
      navigate("/");
    }
  }, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (password === "otpboro") {
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000);

      const cookieString = `login=${decodeURIComponent(
        "true"
      )};expires=${expirationDate.toUTCString()};path=/`;
      document.cookie = cookieString;
      navigate("/");
    } else {
      toast.error("These credentials is wrong.");
    }
  };

  return (
    <>
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Row>
          <Col xs={12}>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  onChange={(e) => {
                    e.preventDefault();
                    setPassword(e.target.value);
                  }}
                />
              </FormGroup>
              <Button color="dark" type="submit">
                Login
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
