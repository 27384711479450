import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Login from "./Login";
import Main from "./Main";
import { ToastContainer } from "react-toastify";
import Loading from "./Widget/Loading";
import { loadingAtom } from "./atom";
import { useAtom } from "jotai";

const App = () => {
  const [loading] = useAtom(loadingAtom);

  return (
    <>
      <React.Suspense fallback={<Loading />}>
        {loading && <Loading />}
        <HashRouter>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </HashRouter>
        <ToastContainer theme="dark" autoClose={500} hideProgressBar={true} />
      </React.Suspense>
    </>
  );
};

export default App;
