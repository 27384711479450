import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import {
  Button,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import axios from "axios";
import { loadingAtom } from "../../atom";
import { toast } from "react-toastify";

interface UploadCSVProps {
  isVisible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const UploadCSVModal: React.FC<UploadCSVProps> = ({
  isVisible,
  setVisible,
}) => {
  const [selectedCSV, setSelectedCSV] = useState<File | null>(null);
  const [loading, setLoading] = useAtom(loadingAtom);

  const handleCSVChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCSV(event.target.files![0]);
  };

  useEffect(() => {
    if (isVisible) {
      setSelectedCSV(null);
    }
  }, [isVisible]);

  const handleUpload = () => {
    if (!selectedCSV) {
      alert("Please upload an csv file");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedCSV);

    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/csv`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((result) => {
        toast.success(result.data.message);
      })
      .catch((err) => {
        toast.error(err.toString());
      })
      .then(() => {
        setLoading(false);
        setSelectedCSV(null);
        setVisible(false);
      });
  };

  return (
    <>
      <Modal isOpen={isVisible} toggle={() => setVisible(false)}>
        <ModalHeader toggle={() => setVisible(false)}>
          Upload CSV File
        </ModalHeader>
        <ModalBody>
          <Input type="file" onChange={handleCSVChange}></Input>
          <FormText>CSV column format must be name,number</FormText>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpload} disabled={loading}>
            Upload
          </Button>
          <Button
            color="secondary"
            onClick={() => setVisible(false)}
            disabled={loading}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default UploadCSVModal;
