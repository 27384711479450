import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAtom } from "jotai";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
  FormText,
} from "reactstrap";
import { checkedAtom, loadingAtom } from "../atom";
import { toast } from "react-toastify";
import ClearGalleryModal from "./BroadcastTab/ClearGalleryModal";

const BroadcastTab = () => {
  const [checked] = useAtom(checkedAtom);
  const [loading, setLoading] = useAtom(loadingAtom);
  const [timeout, setTimeout] = useState(1);
  const [message, setMessage] = useState("");
  const [image, setImage] = useState([]);
  const [dataQR, setDataQR] = useState("");
  const [isSVG, setIsSVG] = useState(false);
  const [showClearGalleryModal, setShowClearGalleryModal] = useState(false);
  const [manualPhone, setManualPhone] = useState<string | null>(null);
  const [chosenImage, setChosenImage] = useState<number>();
  const [chosenImageUrl, setChosenImageUrl] = useState<string>();
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedImage(event.target.files![0]);
  };

  const handleUpload = async () => {
    if (!selectedImage) {
      alert("Please select an image to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedImage);

    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((result) => {
        toast.success(result.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .then(() => {
        setSelectedImage(null);
        setLoading(false);
      });
  };

  const handleChosenImage = (
    e: React.ChangeEvent<HTMLInputElement> | undefined,
    imageId: number,
    imageUrl: string
  ) => {
    if (e?.target.checked) {
      setChosenImage(imageId);
      setChosenImageUrl(imageUrl);
    }
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/settings`)
      .then((result) => {
        setTimeout(
          result.data.data.find(
            (item: { name: string }) => item.name === "timeout"
          )["value"]
        );
        setMessage(
          result.data.data.find(
            (item: { name: string }) => item.name === "message"
          )["value"]
        );
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${process.env.REACT_APP_API_URL}`)
      .then((result) => {
        if (result.headers["content-type"] === "image/svg+xml; charset=utf-8") {
          setIsSVG(true);
        } else {
          setIsSVG(false);
        }
        setDataQR(result.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        setLoading(false);
      });
  }, [setLoading]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/images`)
      .then((result) => {
        setImage(result.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedImage, showClearGalleryModal]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const all = manualPhone
      ? manualPhone.split(",").length === 0 && checked.length === 0
        ? true
        : false
      : true;

    axios
      .post(`${process.env.REACT_APP_API_URL!}/broadcast`, {
        timeout,
        message,
        data: checked,
        manual: manualPhone ? manualPhone.split(",") : [],
        all,
        image: chosenImage,
      })
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      <Container>
        <Row>
          <Col xs={6}>
            <h2>BroadCast</h2>
            <Form onSubmit={handleSubmit}>
              {chosenImageUrl && (
                <img
                  className="w-50"
                  src={`${process.env.REACT_APP_API_URL}/uploads/${chosenImageUrl}`}
                  alt={chosenImageUrl}
                ></img>
              )}
              <FormGroup>
                <Label className="required" for="message">
                  Message
                </Label>
                <Input
                  id="message"
                  name="message"
                  type="textarea"
                  required
                  onChange={(e) => {
                    e.preventDefault();
                    setMessage(e.target.value);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="phones">Broadcast No. Telp Manual</Label>
                <Input
                  id="phones"
                  name="phones"
                  type="text"
                  onChange={(e) => {
                    setManualPhone(e.target.value.replace(/\s/g, ""));
                  }}
                ></Input>
                <FormText>
                  Jika broadcast no.telp manual diisi maka broadcast hanya akan
                  dilakukan ke no yang tercantum di atas ini.
                  <br />
                  Dipisahkan berdasarkan koma (,).
                </FormText>
              </FormGroup>
              <p>
                {manualPhone
                  ? `Broadcast to : ${manualPhone.split(",").join(";")}`
                  : checked.length === 0
                  ? "Total : All"
                  : `Total : ${checked.length}`}
              </p>
              <Button color="info" type="submit">
                Broadcast
              </Button>
            </Form>
          </Col>
          <Col xs={6}>
            {isSVG ? (
              <div
                dangerouslySetInnerHTML={{ __html: dataQR }}
                className="w-50"
              />
            ) : (
              <h3>
                <Badge color="success">{dataQR}</Badge>
              </h3>
            )}
          </Col>
        </Row>
        <Row>
          <h1>Gallery</h1>
          <Col xs={6}>
            <Input type="file" onChange={handleImageChange} />
          </Col>
          <Col xs={6}>
            <Button color="primary" onClick={handleUpload}>
              Upload Image
            </Button>
          </Col>
          {image.length > 0 && (
            <Col>
              <Button
                size="sm"
                color="warning"
                className="float-end"
                onClick={() => setShowClearGalleryModal(true)}
              >
                Clear Gallery
              </Button>
            </Col>
          )}
          <Col
            xs={12}
            className="mt-5 d-flex w-100 flex-wrap  justify-content-evenly"
          >
            {image.map((img, index) => {
              return (
                <div
                  className="w-25 d-flex flex-column align-items-center mx-2 justify-content-between"
                  key={`div${index}`}
                >
                  <Label key={index} for={`image${index}`}>
                    <img
                      className="w-100"
                      id={img["id"]}
                      src={`${process.env.REACT_APP_API_URL}/uploads/${img["url"]}`}
                      alt={img["url"]}
                      key={`img${index}`}
                    ></img>
                  </Label>
                  <Input
                    id={`image${index}`}
                    key={`input${index}`}
                    checked={chosenImage === img["id"]}
                    className="mb-5"
                    type="checkbox"
                    onChange={(e) =>
                      handleChosenImage(e, img["id"], img["url"])
                    }
                  ></Input>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
      <ClearGalleryModal
        isVisible={showClearGalleryModal}
        setVisible={setShowClearGalleryModal}
      />
    </>
  );
};

export default BroadcastTab;
